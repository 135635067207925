import React from "react";
import ReactDOM from "react-dom";
import HomeTwo from "./pages/HomeTwo";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import registerServiceWorker from "./registerServiceWorker";
class Root extends React.Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route exact path={"/"} component={HomeTwo} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
registerServiceWorker();
