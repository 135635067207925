import React from "react";

class SocialMedia extends React.Component {
  render() {
    return (
      <section className="cta bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ul className="list-inline social margin-t-20">
                {/* <li className="list-inline-item">
                  {" "}
                  <Link
                    to="//https://www.facebook.com/profile.php?id=100025941521934"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="mdi mdi-facebook" />
                  </Link>
                </li> */}
                {/* <li className="list-inline-item">
                  {" "}
                  <Link
                    to="//www.github.com/nisha-fatima"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="mdi mdi-github-circle" />
                  </Link>
                </li> */}
                <li className="list-inline-item">
                  {" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=+5561999855310&text=Ola,%20estava%20acessando%20seu%20site%20e%20decidi%20entrar%20em%20contato."
                    target="_blank"
                    className="social-icon"
                    style={{
                      background: "green",
                    }}
                  >
                    <i className="mdi mdi-whatsapp" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 margin-t-30">
              <p className="margin-b-0 contact-title">
                <a href="tel:06133231521">
                  <i className="pe-7s-call" /> &nbsp;(061) 3323-1521
                </a>
              </p>
            </div>
            <div className="col-lg-3 margin-t-30 text-right">
              <p className="contact-title">
                <i className="pe-7s-mail-open" />
                &nbsp; comercial@eastecnocom.com.br
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default SocialMedia;
