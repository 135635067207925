import emailjs from "emailjs-com";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      assunto: "",
      mensagem: "",
    };
  }
  render() {
    const templateParams = {
      nome: this.state.name,
      email: this.state.email,
      assunto: this.state.assunto || "NSip",
      mensagem: this.state.mensagem,
    };

    const contatar = () => {
      emailjs
        .send(
          "service_yiyd0bo",
          "template_ofrag6k",
          templateParams,
          "user_mcChCqbOwLczVJKg5LxmF"
        )
        .then(
          (response) => {
            this.setState({ formModal: false });
            toast.success(
              "✅ Contato recebido! Entraremos em contato em breve."
            );
          },
          (err) => {
            toast.error("❌ Algo deu errado, tente novamente.");
            console.log(err);
          }
        );
    };
    return (
      <section className="section" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">FALE CONOSCO</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
                Caso queira saber mais sobre nossas soluções sob demanda ou até
                mesmo sobre instalações e produtos entre em contato abaixo.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Endereço:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    SCS Quadra 2 Bloco C Ed. Goias Sala 518 - Asa Sul, Brasília
                    - DF, 70317-900, Brasil
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Telefone:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    <a href="tel:06133231521">(061) 3323-1521 </a>
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Whatsapp:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    <a href="https://api.whatsapp.com/send?phone=+5561999855310&text=Ola,%20estava%20acessando%20seu%20site%20e%20decidi%20entrar%20em%20contato.">
                      <i
                        className="mdi mdi-whatsapp"
                        style={{
                          color: "green",
                          fontSize: "1.5em",
                          marginRight: 5,
                        }}
                      />
                      (061) 99985-5310{" "}
                    </a>
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Horário de Atendimento:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    <b>Seg á Sex</b>
                  </span>
                  <span className="text-muted d-block mt-2">8:00 ás 18:00</span>
                  <span className="text-muted d-block mt-2">
                    <b>Sáb</b>
                  </span>
                  <span className="text-muted d-block mt-2">Agendados</span>
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="custom-form mt-4 pt-4">
                <div id="message" />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="Seu Nome*"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Seu email*"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <div className="form-group">
                        <label htmlFor="select" className="">
                          Assunto
                        </label>
                        <select
                          name="select"
                          placeholder="Assunto"
                          id="select"
                          className="form-control is-untouched is-pristine av-valid form-control"
                          onChange={(e) =>
                            this.setState({ assunto: e.target.value })
                          }
                        >
                          <option>NSip</option>
                          <option>Centrais Telefônicas Analógicas</option>
                          <option>Cabeamento Estruturado</option>
                          <option>CFTV</option>
                          <option>Outros</option>
                        </select>
                        <small className="form-text text-muted">
                          Sobre o que deseja falar conosco?
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        className="form-control"
                        placeholder="Mensagem..."
                        onChange={(e) =>
                          this.setState({ mensagem: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-right">
                    <input
                      type="button"
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-custom"
                      value="Enviar"
                      onClick={() => contatar()}
                    />
                    <div id="simple-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    );
  }
}
export default Contact;
