import React from "react";

class Services extends React.Component {
  render() {
    return (
      <section className="section pt-5" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">NOSSOS SERVIÇOS</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
                Além de autorizada das melhores marcas de centrais telefônicas,
                equipamentos de áudio e vídeo, a <b>EAS Tecnocom</b> desenvolve
                suas próprias soluções de acordo com a necessidade do cliente.
              </p>
            </div>
          </div>
          <div className="row margin-t-30">
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-phone text-custom" />
                <h4 className="padding-t-15">Mobilidade</h4>
                <p className="padding-t-15 text-muted">
                  Nossas soluções contam com a possibilidade de uso onde
                  estiver. Desde atendimento de ligações , monitoramento de
                  atendimento á visualizacão de cameras.
                </p>
              </div>
            </div>
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-server text-custom" />
                <h4 className="padding-t-15">Integração</h4>
                <p className="padding-t-15 text-muted">
                  Serviços desenvolvidos para integração com seu banco de dados,
                  trazendo mais tecnologia sem ter que mudar a estrutura.
                </p>
              </div>
            </div>
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-id text-custom" />
                <h4 className="padding-t-15">Agilidade</h4>
                <p className="padding-t-15 text-muted">
                  Contamos com um time especializado em suporte e manutenção de
                  produtos. Temos o critério de atendimento com um prazo máximo
                  de entrega
                </p>
              </div>
            </div>
          </div>
          <div className="row margin-t-30">
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-refresh-cloud text-custom" />
                <h4 className="padding-t-15">Monitoramento</h4>
                <p className="padding-t-15 text-muted">
                  Nossa solução em telefonia possui um sistema de gerenciamento
                  em tempo real. Sendo assim nós somos notificados
                  instantâneamente quando ocorrer alguma instabilidade na sua
                  rede.
                </p>
              </div>
            </div>
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-news-paper text-custom" />
                <h4 className="padding-t-15">Cabeamento</h4>
                <p className="padding-t-15 text-muted">
                  Utilizamos os melhores materiais para que sua instalação de
                  cabeamento e CFTV seja eficaz e duradoura.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-plane text-custom" />
                <h4 className="padding-t-15">Easy to customize</h4>
                <p className="padding-t-15 text-muted">
                  Question Marks and devious Semikoli, but the Little Blind Text
                  didn’t listen. She packed her seven versalia.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}
export default Services;
