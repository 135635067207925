import React from "react";

class Features extends React.Component {
  render() {
    return (
      <section className="section bg-light" id="features">
        <div className="container">
          <div className="row vertical-content">
            <div className="col-lg-5">
              <div className="features-box">
                <h3>Facilitando a produtividade do seu time de atendentes</h3>
                <p className="text-muted web-desc">
                  A EAS Tecnocom possui diversas soluções que trazem conforto e
                  ajuda na produtividade do seu time de atendimento e/ou empresa
                  em geral. Estes são:
                </p>
                <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                  <li className="">Centrais Telefônicas.</li>
                  <li className="">Circuito Fechado de TV (CFTV)</li>
                  <li className="">Cabeamento Estruturado</li>
                  <li className="">Soluções diversas sob demanda</li>
                </ul>
                <a
                  href="#services"
                  className="btn btn-custom margin-t-30 waves-effect waves-light"
                >
                  Saiba Mais <i className="mdi mdi-arrow-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-img features-right text-right">
                <img
                  src="images/grafico.svg"
                  alt="macbook image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
